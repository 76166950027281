export const displayExecResultAgentPage = (execres, task, taskCache) => {
    /*
    We will probably want to rename this function to something else because we already have a component with the same name.

    The second parameter to this function is the task object that represent the runbook task.  
    To address DD-1260, we will need to look for the task_id mention in the execres, and cross check it agains the IDs 
    mentioned in the sub_tasks or descendant arrays of the runbook task object.  If it not mentioned in the sub_tasks array 
    or the descendant arrays, then the task had been deleted but we didn't delete the execution result.  In that case, 
    we do not want to display the execution result.  I cannot seem to reproduce the issue with DD-1260 at the moment, 
    and this function does not get invoked at the time that we delete the child task.  I will come back to this later.
    */
    console.log("displayExecResultAgentPage CALLED")
    const current_runbook_id = getTopRunbookTaskId();
    const is_copilot_page = isCopilotPage();
    
    let index_path = execres?.index_path;
    let runbook_task_id = execres?.runbook_task_id;
    let task_id = execres?.task_id;

    if (isChildOf(task_id, task, taskCache) === false) {
      return;
    }

    const index_path_to_start = execres.runbook_index_path || ""
    const index_path_from_start = execres.index_path || ""
    index_path = normalizedIndexPath(index_path_to_start, index_path_from_start)

    let correct_index_path = "";
    document.querySelectorAll('.hidden_task_id').forEach((elem) => {
      if (elem.innerText.trim() == task_id) {
        let step_number = elem.previousSibling.innerText.trim();
        correct_index_path = buildStartingChildPath(step_number);
      }
    });
    if (correct_index_path) {
      index_path = correct_index_path;
    } else {
      return;
    }

    // Handle update the execution result on the left

    let codeelem = null;
    const plotelem = getIndexPathElem("plot_", runbook_task_id, index_path);
    const containing_element = getIndexPathElem("plot_", runbook_task_id, index_path);
    codeelem = getIndexPathElem("", runbook_task_id, index_path);

    if (codeelem == null) {
      setTimeout(() => {
        displayExecResultAgentPage(execres, task, taskCache)
      }, 100);
    }


    // Handle plotting
    if (execres?.extras?.plots) {
      if (plotelem.innerHTML.trim() == '') {
        const plots = execres.extras.plots || []
        if (plots.length > 0) {
          const plot = plots[0]
          for (var i = 0 ; i < plot.traces.length;i++) {
            plot.traces[i]["type"] = plot.traces[i]["type"] || "lines"
          }
          plotGraphs(plot.title, plot.xlabel, plot.ylabel, plot.traces, plotelem)
        }  
      }
    }

    // Handle displaying tables, blocks
    if (typeof(execres?.extras?.blocks) != 'undefined') {
      // Get ready to render the array of blocks
      // We have to reset.  We do not want the blocks to be repeated multiple times if we receive 
      // multiple events.  We haven't really discuss how we can stream and add  data to an 
      // existing table yet.
      if (containing_element.innerHTML.trim() == '') {
        containing_element.innerHTML = '';

        const blocks = execres?.extras?.blocks || [];
        renderBlocks(blocks, containing_element);  
      }
    }

    // Cache the HTML of the plots / blocks so that we can rerender it later when the user move the task around
    if ((is_copilot_page) && (task_id) && (containing_element != null)) {
      cacheLastOutput(task_id, 'plot', containing_element.innerHTML)
    }

    if ((typeof(execres) != 'undefined') && (execres != null) && (Object.keys(execres).length > 0)) { 
        if (codeelem != null) {
          if (codeelem.innerHTML.trim() == '') {
            let stdall = execres?.stdall || "";

            if (typeof(execres?.exceptions) != 'undefined') {
              const exceptions = (execres.exceptions || []).map(e =>  e.msg)
              if (exceptions.length > 0) {
                stdall += exceptions.join('\n');
              }  
            }
            codeelem.innerHTML = stdall.trim();
            codeelem.parentElement.scrollTop = codeelem.parentElement.scrollHeight;

            // Cache the display so that we can rerender the display later when the user move the task around
            if ((is_copilot_page) && (task_id) && (codeelem != null)) {
              cacheLastOutput(task_id, 'regular', stdall.trim())
            }
          }
        }
    }

    if (codeelem != null) {
      if (codeelem.innerText.trim().search(/\S+/) > -1) {
        codeelem.parentElement.classList.add('left_side_execution_result_with_padding_and_border');
        let hasVerticalScrollbar = codeelem.parentElement.scrollHeight > codeelem.parentElement.clientHeight;
        if (hasVerticalScrollbar) {
          codeelem.parentElement.nextSibling.style.display = 'block';
        } else {
          codeelem.parentElement.nextSibling.style.display = 'none';
        }
      } else {
        codeelem.parentElement.classList.remove('left_side_execution_result_with_padding_and_border');
      }
      try {
        codeelem.parentElement.parentElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
      } catch (ex) {
        console.warn(ex);
      }
    }

    let log_levels = [];
    let log_level = '';
  
    const logs = execres?.extras?.logs || [];  // We need to determine where we can get this information from.
    logs.forEach((log_object) => {
      log_levels.push(log_object.level.toLowerCase())
    })
  
    if (log_levels.includes('error')) {
      log_level = 'error';
    } else if (log_levels.includes('warning')) {
      log_level = 'warning'
    } else if (log_levels.includes('success')) {
      log_level = 'success'
    }
  
    if (log_level != '') {
      let css_class = 'left_side_execution_result_' + log_level;
      if (codeelem != null) {
        let task_el = findAncestorWithClass(codeelem, 'title_row')
        task_el.classList.add(css_class);
      }
    }

    // Handle updating the input / output parameters
    //console.log(`TRYING TO UPDATE INPUT / OUTPUT PARAMETERS.  msg:`, msg)
    if ((typeof(execres) != 'undefined') && (Object.keys(execres).length > 0)) {
      displayInputOutput(runbook_task_id, index_path, execres);
    }

}