export const updateTaskExecResultsView = (root_task_id, index_path, results, job_id, iteration, is_displayJob) => {
  if (typeof(window) != 'undefined') {
    if (! isAuthenticated()) {
      // If the user is not authenticaed, do not display execution result.
      return;
    }
  }

  let update = shouldUpdate(job_id, iteration, is_displayJob);

  if ( update ) {
    try {
      const plotelem = getIndexPathElem("plot_", root_task_id, index_path)
      const plots = results.extras.plots || []
      if (plots.length > 0) {
        const plot = plots[0]
          for (var i = 0 ; i < plot.traces.length;i++) {
            plot.traces[i]["type"] = plot.traces[i]["type"] || "lines"
          }
          plotGraphs(plot.title, plot.xlabel, plot.ylabel, plot.traces, plotelem)
      }

      const blocks = results?.extras?.blocks || [];
      renderBlocks(blocks, plotelem);

    
      let stdall = results.stdall || "";
      const exceptions = (results.exceptions || []).map(e =>  e.msg)
      if (exceptions.length > 0) {
        stdall += exceptions.join('\n');
      }
    
      const codeelem = getIndexPathElem("", root_task_id, index_path);
      if ((codeelem != null) && (stdall != '')) {
        codeelem.innerText = stdall;
        if (stdall.search(/\S+/) > -1) {
          codeelem.parentElement.classList.add('left_side_execution_result_with_padding_and_border');
          let hasVerticalScrollbar = codeelem.parentElement.scrollHeight > codeelem.parentElement.clientHeight;
          if (hasVerticalScrollbar) {
            codeelem.parentElement.nextSibling.style.display = 'block';
          } else {
            codeelem.parentElement.nextSibling.style.display = 'none';
          }
        } else {
          codeelem.parentElement.classList.remove('left_side_execution_result_with_padding_and_border');
        }
      }
      // Display the input and output parameters based on the information available as part of the results / message
      displayInputOutput(root_task_id, index_path, results)

      if (codeelem != null) {
        try {
          codeelem.parentElement.parentElement.parentElement.parentElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
        } catch (ex) {
          console.warn(ex);
        }
      }
  
      let log_levels = [];
      let log_level = '';
    
      const logs = results.extras.logs || [];
      logs.forEach((log_object) => {
        log_levels.push(log_object.level.toLowerCase())
      })
    
      if (log_levels.includes('error')) {
        log_level = 'error';
      } else if (log_levels.includes('warning')) {
        log_level = 'warning'
      } else if (log_levels.includes('success')) {
        log_level = 'success'
      }
    
      if (log_level != '') {
        let css_class = 'left_side_execution_result_' + log_level;
        if (codeelem != null) {
          let task_el = findAncestorWithClass(codeelem, 'title_row')
          task_el.classList.add(css_class);
        }
      }
  
    } catch (e) {
      console.warn('An error happened inside updateTaskExecResultsView:', e);
    }  
  }
}
