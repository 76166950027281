export const plotGraphs = (title, x_label, y_label, traces, inGraphDiv=null) => {
  var graphDiv = inGraphDiv;
  if (graphDiv == null){
    graphDiv = document.createElement('div');
  }
  
  const colors = [
     '#1f77b4',  // muted blue
     '#ff7f0e',  // safety orange
     '#2ca02c',  // cooked asparagus green
     '#d62728',  // brick red
     '#9467bd',  // muted purple
     '#8c564b',  // chestnut brown
     '#e377c2',  // raspberry yogurt pink
     '#7f7f7f',  // middle gray
     '#bcbd22',  // curry yellow-green
     '#17becf'   // blue-teal
  ];
  for (var t=0; t < traces.length; t++){
    if (traces[t]["type"] == 'pie' && !traces[t].hasOwnProperty('marker')) {
      traces[t]['marker'] = {};
      traces[t]['marker']['colors'] = colors;          
    }
  }
  var layout = {
      title: {
        text:title,
        font: {
          family: 'Courier New, monospace',
          size: 24
        },
        xref: 'paper',
        x: 0.05,
      },
      xaxis: {
        title: x_label
      },
      yaxis: {
        title: y_label
      }
    }

  //data = JSON.parse(traces)
  Plotly.newPlot(graphDiv, traces, layout).then(function(gd){
    /*
    This function is invoked as part of replayConv and therefore can potentially send this image over to Slack over and over again.
    Currently, we are setting the flag_enable_slack variable after the getSlackChannelsDropdown call, which seems to be invoked after 
    replayConv so we seems to be ok for now, but we need to come up with a better way to know if this function is being invoked as 
    part of replayConv.
    */
    /*
    if ((typeof(flag_enable_slack) != 'undefined') && (flag_enable_slack)) {
      Plotly.toImage(graphDiv,{format:'jpeg'}).then(function(url){
        var reqObj = new Object();
        reqObj.conv_id = current_conv_id;
        reqObj.image_format = 'jpeg';
        reqObj.image_data = url;
        sendARequest('/postImageToSlack', reqObj);
      })
    }
    */
  });
  return graphDiv;
}